<template>
    <div class="relative h-full product-card transition hover:-translate-y-2 w-full">
        <nuxt-link
            :to="slug"
            class="product-card__inner-wrapper w-full"
            :class="autoHeight ? 'h-auto' : 'h-full'"
            @click="$store.commit('toggleSearchDropdown', false)"
        >
            <div
                :class="isSemiRounded ? 'rounded-r-md' : 'rounded-md lg:rounded-md'"
                class="flex flex-col bg-white border border-black border-opacity-10 overflow-hidden h-full justify-between transition relative hover:border-opacity-20 card"
            >
                <div
                    v-if="discountedPrice || discountedBruttoPrice"
                    class="discount-badge bg-red-100 flex justify-center items-center w-[200px] text-white z-40 absolute -rotate-45 left-0 top-0 text-base md:text-lg font-Inter-Semibold"
                >
                    -{{ Math.ceil(100 - (discountedPrice / price) * 100) }}%
                </div>
                <div class="aspect-w-1 aspect-h-1">
                    <img :alt="title" :src="image" class="object-contain product-card__image" />
                </div>
                <h3
                    :class="md ? 'lg:text-base leading-5' : 'lg:text-base 3xl:text-lg'"
                    class="font-Inter-Bold mb-2 mt-4 px-2 lg:pl-5 lg:pr-3 text-left text-sm"
                >
                    {{ title }}
                </h3>
                <h4
                    v-if="parameter && parameter[0]?.parameter_slug === 'gravirozott-termek'"
                    class="text-sm font-Inter-Regular px-2 lg:pl-5 lg:pr-3 mb-3"
                >
                    {{ parameter[0].parameter_name }}
                </h4>
                <div
                    v-if="
                        false // rating && $device.isDesktop
                    "
                    class="px-3 lg:pl-6 lg:pr-3 mb-3 lg:mb-6"
                >
                    <Rating :rating-value="parseFloat(rating)" :review-count="400" />
                </div>
                <div class="flex flex-col lg:flex-row lg:items-center px-3 lg:pl-6 lg:pr-3">
                    <div class="w-full lg:w-9/12 lg:text-center">
                        <div>
                            <div
                                class="flex flex-col gap-y-2 py-2 lg:py-4 border-t border-dashed lg:border-b border-black border-opacity-10 lg:pr-4"
                            >
                                <div class="flex w-full lg:items-end flex-col lg:flex-row">
                                    <h4 :class="md ? 'text-sm md:text-base' : 'text-base'">
                                        <span class="font-Inter-Bold"
                                            >{{ $formatCurrency(discountedPrice ? discountedPrice : price) }}
                                        </span>
                                        <span class="font-Inter-Regular">-tól</span>
                                    </h4>
                                    <h5
                                        v-if="discountedPrice || discountedBruttoPrice"
                                        class="font-Inter-Semibold text-red-100 line-through lg:ml-2 text-sm 3xl:text-base lg:whitespace-nowrap"
                                    >
                                        {{ $formatCurrency(price) }} Ft
                                    </h5>
                                </div>
                                <div v-if="false" class="flex w-full opacity-50">
                                    <span
                                        class="text-left font-Inter-Regular text-xs"
                                        :class="md ? 'lg:text-sm' : 'lg:text-sm 3xl:text-base'"
                                    >
                                        Bruttó ár:
                                        {{
                                            $formatCurrency(discountedBruttoPrice ? discountedBruttoPrice : bruttoPrice)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full lg:w-3/12 hidden md:flex justify-end">
                        <div
                            v-if="viewOnlyBtn"
                            class="transition font-Inter-Semibold text-center rounded-full bg-yellow-100 hover:bg-yellow-200 my-4 lg:my-0 lg:p-0 w-full flex items-center justify-center text-black text-lg opacity-100 cursor-pointer lg:h-[2.85rem] lg:w-[2.85rem]"
                            :class="[hasBtnText ? 'py-2' : 'py-1']"
                        >
                            <span
                                v-if="hasBtnText"
                                class="block font-Inter-Bold text-lg lg:text-base leading-none lg:hidden mr-2"
                                >Megtekintem</span
                            >
                            <i
                                class="ri-eye-line text-xl text-black lg:text-[1.65rem] blinking-eye"
                                :class="md ? 'text-[1.65rem]' : 'text-[1.29rem]'"
                            ></i>
                        </div>
                        <div
                            v-else
                            class="transition font-Inter-Semibold text-center rounded-full text-lg bg-yellow-100 hover:bg-yellow-200 py-2 my-4 lg:my-0 lg:p-0 w-full flex items-center justify-center text-black opacity-100 cursor-pointer"
                            :class="md ? 'lg:h-14 lg:w-14' : 'lg:h-16 lg:w-16'"
                            @click="addToCart(product)"
                        >
                            <span class="text-lg block lg:hidden mr-3">Kosárba</span>
                            <i
                                class="ri-shopping-cart-line text-xl lg:text-2xl"
                                :class="md ? 'text-xl' : 'text-2xl'"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </nuxt-link>
        <div
            class="absolute right-[6px] md:right-2 top-[6px] md:top-2 w-8 h-8 flex items-center justify-center rounded-full transition-all min-w-0 bg-no-repeat bg-center bg-contain cursor-pointer"
            @click="$auth.loggedIn ? addToWishlist(product) : $store.commit('globals/toggleLoginModal', true)"
        >
            <i
                class="text-[1.4rem] md:text-[1.6rem] text-center transition hover:text-grey-400"
                :class="
                    isOnWishlist
                        ? 'ri-heart-fill pointer-events-none text-red-100 opacity-100 bounce'
                        : 'ri-heart-fill text-grey-300'
                "
            ></i>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Rating from '~/components/UI/Rating.vue';

export default {
    name: 'ProductCard',
    components: { Rating },
    props: {
        title: {
            type: String,
            required: true,
        },
        parameter: {
            type: [Object, Array],
            required: false,
            default: () => {},
        },
        rating: {
            type: Number,
            required: false,
            default: null,
        },
        product: {
            type: Object,
            required: false,
            default: () => {},
        },
        image: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
        bruttoPrice: {
            type: Number,
            required: true,
        },
        discountedPrice: {
            type: Number,
            required: false,
            default: null,
        },
        discountedBruttoPrice: {
            type: Number,
            required: false,
            default: null,
        },
        slug: {
            type: String,
            required: true,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasBtnText: {
            type: Boolean,
            required: false,
            default: true,
        },
        viewOnlyBtn: {
            type: Boolean,
            required: false,
            default: true,
        },
        isSemiRounded: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFavorite: {
            type: Boolean,
            required: false,
            default: false,
        },
        autoHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            isOnWishlist: false,
        };
    },
    computed: {
        ...mapState(['liked_products']),
    },
    mounted() {
        if (this.isFavorite) {
            this.isOnWishlist = true;
        }
    },
    methods: {
        ...mapActions(['addProductToCart', 'likeProduct']),

        addToCart(product) {
            this.addProductToCart({ product, quantity: 1 });
        },
        addToWishlist(product) {
            if (this.isFavorite || this.isOnWishlist) {
                try {
                    this.$axios.$post(`/remove-favorite/${product.id}`).then((res) => {
                        if (res.success === true) {
                            this.isOnWishlist = false;
                            setTimeout(() => {
                                this.$store.commit('globals/setNotifications', {
                                    title: 'Eltávolítottad kedvencek közül',
                                    subtitle: 'A terméket sikeresen eltávolítottad a kedvenceid közül.',
                                    emoji: 'waving_hand_emoji.png',
                                });
                            }, 1000);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            } else {
                try {
                    this.$axios.$post(`/add-favorite/${product.id}`).then((res) => {
                        if (res.success === true) {
                            this.isOnWishlist = true;
                            setTimeout(() => {
                                this.$store.commit('globals/setNotifications', {
                                    title: 'Kedvencnek jelölted',
                                    subtitle: 'A terméket sikeresen hozzáadtad a kedvenceid közül.',
                                    emoji: 'heart_eyes_emoji.png',
                                });
                            }, 1000);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        },
    },
};
</script>

<style>
.card {
    &:hover {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.075);
    }
}

.cart-button {
    background-image: url('~/assets/images/icons/cart-icon-stroke.svg');

    &:hover {
        background-image: url('~/assets/images/icons/cart-icon-stroke--white.svg');
    }

    &--md {
        background-size: 1.5rem;
        @media (max-width: 991px) {
            background-size: 1.25rem !important;
        }
    }
}

.bounce {
    @keyframes bounce {
        0% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1);
        }
    }

    @apply transition-all scale-100;
    animation: bounce 0.25s forwards;
}

.discount-badge {
    top: 1.5625rem;
    left: -3.9rem;
    @media (max-width: 991px) {
        top: 1rem;
        left: -4.5rem;
    }
}

.product-card {
    &:hover {
        .blinking-eye {
            @keyframes blinking {
                0% {
                    transform: scaleY(1);
                }
                5% {
                    transform: scaleY(0);
                }
                10% {
                    transform: scaleY(1);
                }
                50% {
                    transform: scaleY(1);
                }
                55% {
                    transform: scaleY(0);
                }
                60% {
                    transform: scaleY(1);
                }
                100% {
                    transform: scaleY(1);
                }
            }

            animation: blinking 4s infinite;
        }
    }
}

.product-card__image {
    &.isLoading {
        @keyframes fadeInImage {
            from {
                @apply opacity-0 -translate-y-4;
            }
            to {
                @apply opacity-100 translate-y-0;
            }
        }
        animation: fadeInImage 0.25s forwards 0.5s;
    }

    &.isLoaded {
        @apply opacity-100 translate-y-0;
    }
}
</style>
