export const ADD_TO_CART = (state, { product, quantity }) => {
    const productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
    });

    if (productInCart) {
        productInCart.quantity += quantity;
        return;
    }

    state.cart.push({ product, quantity });
};

export const LIKE_PRODUCT = (state, product) => {
    const isProductLiked = state.liked_products.find((item) => {
        return item.id === product.id;
    });

    if (isProductLiked) {
        return;
    }
    state.liked_products.push(product);
};

export const DISLIKE_PRODUCT = (state, product) => {
    state.liked_products = state.liked_products.filter((item) => {
        return item.id !== product.id;
    });
};

export const REMOVE_PRODUCT_FORM_CART = (state, product) => {
    state.cart = state.cart.filter((item) => {
        return item.product.id !== product.id;
    });
};

export const UPDATE_PRODUCT_QUANTITY = (state, { product, quantity }) => {
    const cartItem = state.cart.find((item) => item.product.id === product.id);
    if (cartItem) {
        cartItem.quantity = quantity;
    }
};

export const UPDATE_CHECKOUT_DETAILS = (state, payload) => {
    state.checkout_details = { ...state.checkout_details, ...payload.details };
};

export const UPDATE_INVOICE_DETAILS = (state, payload) => {
    state.invoice_details = { ...state.invoice_details, ...payload };
};

export const SET_CHECKOUT_ERRORS = (state, payload) => {
    state.checkout_errors = {};
    state.checkout_errors = { ...state.checkout_errors, ...payload.details };
};

export const EMPTY_CHECKOUT_DETAILS = (state) => {
    state.checkout_details = {
        aszf: null,
        billing_address: null,
        billing_city: null,
        billing_first_name: null,
        billing_last_name: null,
        billing_postal_code: null,
        email: null,
        phone: null,
        shipping_address: null,
        shipping_city: null,
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_postal_code: null,
        message: null,
        selected_shipping_method: null,
        selected_payment_method: null,
        is_loading: false,
        foxpost_point: null,
    };
    localStorage.setItem('checkoutDetails', JSON.stringify({}));
};

export const EMPTY_SHIPPING_METHOD = (state) => {
    state.checkout_details.selected_shipping_method = null;
};

export const SET_FOXPOST_DETAILS = (state, payload) => {
    state.checkout_details.foxpost_point = payload;
};

export const SET_SHIPPING_METHODS = (state, payload) => {
    for (const item of payload) {
        state.shipping_methods.push(item);
    }
};

export const EMPTY_FOXPOST_DETAILS = (state) => {
    state.checkout_details.foxpost_point = null;
};

export const STORE_CUSTOMIZED_PRODUCT_DETAILS = (state, payload) => {
    const existingIndex = state.customized_products.findIndex((product) => product.id === payload.id);

    if (existingIndex !== -1) {
        state.customized_products.splice(existingIndex, 1, payload);
    } else {
        state.customized_products.push(payload);
    }

    // Save the updated customizedProducts to localStorage
    if (process.client) {
        localStorage.setItem('customizedProducts', JSON.stringify(state.customized_products));
    }
};
