<template>
    <div>
        <header
            class="fixed top-0 left-0 w-full transition z-20"
            :class="{ 'xl:-translate-y-11 is-thin': $store.state.globals.isHeaderThin }"
        >
            <div class="bg-grey-100 py-3 hidden xl:flex">
                <div class="container">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center gap-x-10">
                            <div v-for="item in headerItems.slice(0, 2)" :key="item.id">
                                <nuxt-link
                                    class="header-link flex items-center text-black text-sm leading-none font-Inter-Bold hover:underline"
                                    :to="localePath(item.to)"
                                    >{{ item.title }}
                                </nuxt-link>
                            </div>
                        </div>
                        <div class="flex items-center gap-x-10">
                            <div v-if="$auth.loggedIn" class="flex items-center gap-x-10">
                                <div class="cursor-pointer">
                                    <nuxt-link
                                        :to="localePath('user-favourite-products')"
                                        class="header-link flex items-center text-black text-sm leading-none font-Inter-Bold hover:underline header-icon header-icon header-icon-hearth"
                                        >Kedvencek
                                    </nuxt-link>
                                </div>
                                <div class="cursor-pointer">
                                    <nuxt-link
                                        :to="localePath('user')"
                                        class="header-link flex items-center text-black text-sm leading-none font-Inter-Bold hover:underline header-icon header-icon header-icon-user"
                                        >{{
                                            $auth.user.last_name + ' ' + $auth.user.first_name || 'Felhasználó'
                                        }}</nuxt-link
                                    >
                                </div>
                                <div class="cursor-pointer" @click="handleLogout">
                                    <div
                                        class="header-link flex items-center text-black text-sm leading-none font-Inter-Bold hover:underline header-icon header-icon header-icon-login"
                                    >
                                        Kijelentkezés
                                    </div>
                                </div>
                            </div>
                            <div v-else class="flex items-center gap-x-10">
                                <div class="cursor-pointer" @click="openLoginModal">
                                    <span
                                        class="header-link flex items-center text-black text-sm leading-none font-Inter-Bold hover:underline header-icon header-icon header-icon-login"
                                        >Bejelentkezés</span
                                    >
                                </div>
                                <div class="cursor-pointer">
                                    <span
                                        class="header-link block flex items-center text-black text-base text-sm leading-none font-Inter-Bold hover:underline header-icon header-icon header-icon-registration"
                                        @click="openRegistrationModal"
                                        >Regisztráció</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white header-inner">
                <div class="container px-4 lg:px-5 sm:py-1 lg:py-0 relative">
                    <div
                        v-if="$store.state.globals.notifications.length"
                        class="flex flex-col gap-y-4 absolute right-0 md::right-4 -mt-5 md:mt-0 top-[5.5rem] w-full sm:w-auto z-[-1]"
                    >
                        <ToastMessage
                            v-for="(notification, index) in $store.state.globals.notifications"
                            :key="`notification-${index}`"
                            is-visible
                            :title="notification.title"
                            :subtitle="notification.subtitle"
                            :emoji="notification.emoji"
                        />
                    </div>
                    <div class="flex items-center justify-between mr-2 md:mr-0 relative">
                        <div class="flex lg:hidden items-center gap-x-2 lg:gap-x-6">
                            <HamburgerBtn />
                            <i class="ri-search-line text-[1.6rem] text-black scale-90" @click="openSearchDropdown"></i>
                        </div>

                        <div
                            class="py-1 md:py-3 xl:py-5 items-center gap-x-4 xl:gap-x-0 flex justify-center lg:justify-start w-max lg:w-full min-h-[3.25rem] relative"
                            :class="isWhispersOfWood && 'lg:justify-between pr-8'"
                        >
                            <div class="absolute lg:relative" @click="handleLogoEvents()">
                                <nuxt-link class="md:mr-5 xl:mr-8" to="/" aria-label="Kartapolis" title="Kartapolis">
                                    <Logo
                                        class="translate-y-1 w-40 md:w-[11.75rem] min-w-[10rem] md:min-w-[11.75rem] max-w-[10rem] md:max-w-[11.75rem] md:w-auto"
                                    />
                                </nuxt-link>
                            </div>
                            <div
                                class="fixed lg:relative top-12 lg:top-0 bg-white lg:bg-transparent h-[calc(100dvh-3rem)] lg:h-full w-screen left-0 px-5 lg:px-0 pb-0 md::pb-28 lg:pb-0 flex flex-col lg:flex-row pt-4 lg:pt-0 border-t border-black border-opacity-10 lg:border-none overflow-y-scroll lg:overflow-visible nicescroll z-[-1] lg:z-1 lg:w-auto lg:translate-x-0"
                                :class="{
                                    'translate-x-0': $store.state.globals.isMobileMenuOpen,
                                    'translate-x-[-100%]': !$store.state.globals.isMobileMenuOpen,
                                    'transition-all': $device.isMobile,
                                }"
                            >
                                <ProductCategoriesDropdown
                                    class="md:mr-8"
                                    :class="$device.isDesktop ? 'mt-6 lg:mt-0' : 'mt-3 md:mt-0'"
                                />
                                <div v-if="$device.isMobile" class="mt-5 xl:mb-0" @click="closeMobileMenu">
                                    <div
                                        class="transition mb-6 text-center rounded-full bg-grey-25 hover:bg-grey-50 text-black text-lg py-2 px-4 lg:px-3 border border-black border-opacity-30 cursor-pointer flex lg:hidden items-center justify-center w-full"
                                        @click="openSearchDropdown"
                                    >
                                        <span class="font-Inter-Bold mr-3 text-base">Keresés</span>
                                        <i class="ri-search-2-line text-[1.25rem]"></i>
                                    </div>
                                </div>

                                <transition name="fade" mode="in-out">
                                    <div
                                        class="flex items-start lg:items-center flex-col lg:flex-row gap-x-0 lg:gap-x-5 xl:gap-x-8 gap-y-6 lg:gap-y-0 header-main-items"
                                        :class="$device.isDesktop && 'mt-6 lg:mt-0'"
                                    >
                                        <div class="flex justify-center relative" @click="closeMobileMenu">
                                            <nuxt-link
                                                :to="localePath('products')"
                                                class="header-item text-base w-full font-Inter-Bold text-black leading-none hover:underline"
                                                >Termékek
                                            </nuxt-link>
                                        </div>

                                        <div class="flex justify-center relative" @click="closeMobileMenu()">
                                            <nuxt-link
                                                :to="localePath('editors')"
                                                class="header-item text-base w-full font-Inter-Bold text-black leading-none hover:underline"
                                                >Szerkesztők
                                            </nuxt-link>
                                        </div>
                                        <div
                                            v-if="!isWhispersOfWood"
                                            class="flex justify-center relative"
                                            @click="closeMobileMenu()"
                                        >
                                            <nuxt-link
                                                :to="localePath('magazine')"
                                                class="header-item text-base w-full font-Inter-Bold text-black leading-none hover:underline"
                                                >Magazin
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </transition>
                                <div class="flex justify-between flex-col mt-6 lg:hidden">
                                    <div class="flex gap-y-6 flex-col">
                                        <div
                                            v-for="item in headerItems.slice(0, 4)"
                                            :key="item.id"
                                            @click="closeMobileMenu()"
                                        >
                                            <nuxt-link
                                                class="text-base font-Inter-Bold text-black leading-none hover:underline"
                                                :to="item.to"
                                                >{{ item.title }}
                                            </nuxt-link>
                                        </div>
                                    </div>
                                    <div
                                        class="flex gap-y-4 flex-col mt-8 pt-6 border-t border-black border-opacity-10 lg:border-none lg:pt-0 xl:mt-4 items-start xl:items-baseline"
                                    >
                                        <div v-if="$auth.loggedIn" class="flex flex-col gap-y-4 w-full">
                                            <nuxt-link
                                                :to="localePath('user-favourite-products')"
                                                class="header-link-mobile-wrapper py-2 xl:p-0 xl:bg-transparent w-full xl:w-auto flex justify-center rounded-full xl:justify-end"
                                            >
                                                <span
                                                    class="flex header-link-mobile items-center text-black leading-none font-Inter-Bold hover:underline header-icon text-base header-icon-mobile header-icon-mobile-hearth"
                                                    @click="closeMobileMenu"
                                                    >Kedvencek</span
                                                >
                                            </nuxt-link>
                                            <nuxt-link
                                                :to="localePath('user')"
                                                class="header-link-mobile-wrapper py-2 xl:p-0 bg-blue-100 bg-opacity-10 xl:bg-transparent w-full xl:w-auto flex justify-center rounded-full xl:justify-end"
                                            >
                                                <span
                                                    class="flex header-link-mobile items-center text-blue-100 xl:text-black leading-none font-Inter-Bold hover:underline header-icon text-base header-icon-mobile header-icon-mobile-registration"
                                                    @click="closeMobileMenu"
                                                >
                                                    {{
                                                        $auth.user.last_name + ' ' + $auth.user.first_name ||
                                                        'Felhasználó'
                                                    }}</span
                                                >
                                            </nuxt-link>
                                            <div
                                                class="header-link-mobile-wrapper py-2 xl:p-0 bg-blue-100 xl:bg-transparent w-full xl:w-auto flex justify-center rounded-full xl:justify-end min-h-[2.775rem] lg:h-auto"
                                                @click="handleLogout"
                                            >
                                                <span
                                                    class="flex header-link-mobile items-center text-white xl:text-black leading-none font-Inter-Bold hover:underline header-icon text-base header-icon-mobile header-icon-mobile-login"
                                                    >Kijelentkezés</span
                                                >
                                            </div>
                                        </div>
                                        <div v-else class="flex flex-col gap-y-4 w-full">
                                            <div
                                                class="header-link-mobile-wrapper py-2 xl:p-0 bg-blue-100 xl:bg-transparent w-full xl:w-auto flex justify-center rounded-full xl:justify-end min-h-[2.775rem] lg:h-auto"
                                                @click="openRegistrationModal"
                                            >
                                                <span
                                                    class="flex header-link-mobile items-center text-white xl:text-black leading-none font-Inter-Bold hover:underline header-icon text-base header-icon-mobile header-icon-mobile-login"
                                                    >Regisztráció</span
                                                >
                                            </div>
                                            <div
                                                class="header-link-mobile-wrapper py-2 xl:p-0 bg-blue-100 bg-opacity-10 xl:bg-transparent w-full xl:w-auto flex justify-center rounded-full xl:justify-end"
                                                @click="openLoginModal"
                                            >
                                                <span
                                                    class="flex header-link-mobile items-center text-blue-100 xl:text-black leading-none font-Inter-Bold hover:underline header-icon text-base header-icon-mobile header-icon-mobile-registration"
                                                    >Bejelentkezés</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center gap-x-3 lg:gap-x-6">
                            <nuxt-link v-if="$auth.loggedIn" :to="localePath('user')" class="lg:hidden">
                                <i class="ri-user-line text-[1.6rem] text-black"></i>
                            </nuxt-link>
                            <div
                                class="transition text-center rounded-full bg-grey-25 hover:bg-grey-50 text-black hidden lg:flex justify-center items-center text-lg py-3 lg:py-2 px-4 lg:px-3 border border-black border-opacity-10 cursor-pointer min-w-[3rem] min-h-[3rem] max-w-[3rem] max-h-[3rem] w-[3rem] h-[3rem]"
                                @click="openSearchDropdown"
                            >
                                <i class="ri-search-2-line text-[1.5rem]"></i>
                            </div>
                            <CartButton />
                        </div>
                    </div>
                </div>
                <ReadProgressBar />
            </div>
            <LoginModal :is-open="$store.state.globals.isLoginModalOpen" :close="closeLoginModal" />
            <RegistrationModal
                :is-open="$store.state.globals.isRegistrationModalOpen"
                :close="closeRegistrationModal"
            />
            <UserModal :is-open="isUserModalOpen" :close="closeUserModal" />
        </header>
        <SearchDropdown
            :is-open="isSearchDropdownOpen"
            :close="closeSearchDropdown"
            :class="$store.state.globals.isHeaderThin && 'xl:-translate-y-11'"
        />
        <LoadingScreen />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/UI/Logo.vue';
import ProductCategoriesDropdown from '@/components/ProductCategoriesDropdown.vue';
import HamburgerBtn from '@/components/UI/Buttons/HamburgerBtn.vue';
import UserModal from '~/components/Modals/UserModal.vue';
import RegistrationModal from '~/components/Modals/RegistrationModal.vue';
import LoginModal from '~/components/Modals/LoginModal.vue';
import CartButton from '~/components/UI/Buttons/CartButton.vue';
import SearchDropdown from '~/components/Forms/SearchDropdown.vue';
import ReadProgressBar from '~/components/UI/Buttons/ReadProgressBar.vue';
import LoadingScreen from '@/components/UI/LoadingScreen.vue';
import ToastMessage from '@/components/UI/ToastMessage.vue';
export default {
    name: 'Header',
    components: {
        Logo,
        ProductCategoriesDropdown,
        HamburgerBtn,
        UserModal,
        RegistrationModal,
        LoginModal,
        CartButton,
        SearchDropdown,
        ReadProgressBar,
        LoadingScreen,
        ToastMessage,
    },
    data() {
        return {
            headerItems: [
                { id: 3, title: 'Kapcsolat', to: '/kapcsolat', icon: '' },
                { id: 4, title: 'Karrier', to: 'career', icon: '' },
            ],
            lastScroll: 0,
            isSearchDropdownOpen: false,
            isLoginModalOpen: false,
            isRegistrationModalOpen: false,
            isUserModalOpen: false,
            isErrorVisible: false,
            isWhispersOfWood: false,
            currentUserTab: 1,
        };
    },
    computed: {
        ...mapGetters({
            cartItemCount: 'cartItemCount',
        }),
    },
    watch: {
        isLoginModalOpen(value) {
            if (value) {
                this.$store.commit('globals/toggleMobileMenu', false);
            }
        },
        isRegistrationModalOpen(value) {
            if (value) {
                this.$store.commit('globals/toggleMobileMenu', false);
            }
        },
    },
    mounted() {
        this.isWhispersOfWood = this.$route.path === '/whispers-of-wood';
        window.addEventListener('scroll', this.toggleHeader);
        // this.$store.dispatch('auth/fetchUser');
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.toggleHeader);
    },
    methods: {
        toggleHeader() {
            const scrollTop = window.scrollY;
            if (window.innerWidth > 1100 || this.$device.isDesktop) {
                this.$store.commit('globals/setHeaderThin', scrollTop >= this.lastScroll);
                this.lastScroll = window.scrollY;
            }
        },
        openLoginModal() {
            this.$store.commit('globals/toggleLoginModal', true);
        },
        closeLoginModal() {
            this.$store.commit('globals/toggleLoginModal', false);
        },
        handleLogoEvents() {
            this.$scrollToTop();
            if (this.$device.isMobile) {
                this.$store.commit('globals/toggleMobileMenu', false);
                this.isSearchDropdownOpen = false;
            }
        },
        handleLogout() {
            this.$auth.logout();
            if (this.$device.isDesktop) {
                this.$store.commit('globals/setHeaderThin', true);
                setTimeout(() => {
                    this.$store.commit('globals/setHeaderThin', false);
                }, 750);
            } else {
                this.$store.commit('globals/toggleMobileMenu', false);
            }
            this.$router.push('/');
            setTimeout(() => {
                this.$store.commit('globals/setNotifications', {
                    title: 'Sikeres kijelentkezés',
                    subtitle: 'Kilépés sikeres, várunk vissza!',
                    emoji: 'waving_hand_emoji.png',
                });
            }, 2000);
        },
        closeMobileMenu() {
            if (window.innerWidth > 1023) return;
            this.$store.commit('globals/toggleMobileMenu', false);
            if (this.$store.state.globals.isMobileMenuOpen) {
                this.isSearchDropdownOpen = false;
            }
        },
        openRegistrationModal() {
            this.$store.commit('globals/toggleRegistrationModal', true);
        },
        closeRegistrationModal() {
            this.$store.commit('globals/toggleRegistrationModal', false);
        },
        closeUserModal() {
            this.isUserModalOpen = false;
        },
        openSearchDropdown() {
            this.isSearchDropdownOpen = true;
        },
        closeSearchDropdown() {
            this.isSearchDropdownOpen = false;
        },
    },
};
</script>

<style scoped>
header {
    box-shadow: 0 4px 20px rgba(29, 29, 29, 0.03);
}

.header-inner {
    box-shadow: 0 4px 21px rgba(29, 29, 29, 0.1);
}

.search-btn {
    background-image: url('~/assets/images/icons/magnifier-icon.svg');
    @media (max-width: 992px) {
        background-size: 1.6rem;
    }
}

.cart-btn {
    background-image: url('~/assets/images/icons/cart-icon.svg');
    @media (max-width: 1200px) {
        background-size: 1.4rem;
        outline: 1px solid #08dc6a !important;
    }
}

.header-link {
    &.header-icon {
        &::before {
            content: '';
            background-size: auto 1rem;
            @apply h-5 w-5 bg-no-repeat bg-center bg-contain block mr-3 transition-all;
        }
    }

    &.header-icon-registration {
        &::before {
            background-image: url('~/assets/images/icons/registration-icon.svg');
        }
    }

    &.header-icon-hearth {
        &::before {
            background-image: url('~/assets/images/icons/hearth-icon-black.svg');
        }
    }

    &.header-icon-login {
        &::before {
            background-image: url('~/assets/images/icons/login-icon.svg');
        }
    }

    &.header-icon-user {
        &::before {
            background-size: 1.45rem;
            background-image: url('~/assets/images/icons/user-icon.svg');
        }
    }
}

.header-link-mobile {
    &.header-icon-mobile {
        &::before {
            content: '';
            background-size: auto 1rem;
            @apply h-5 w-5 bg-no-repeat bg-center bg-contain block mr-3 transition-all;
        }
    }

    &.header-icon-mobile-registration {
        &::before {
            background-image: url('~/assets/images/icons/registration-icon.svg');
        }
    }

    &.header-icon-mobile-login {
        &::before {
            background-image: url('~/assets/images/icons/login-icon-white.svg');
        }
    }

    &.header-icon-mobile-hearth {
        &::before {
            background-image: url('~/assets/images/icons/hearth-icon-black.svg');
        }
    }

    &.header-icon-mobile-search {
        &::before {
            background-image: url('~/assets/images/icons/magnifier-icon.svg');
        }
    }

    &.header-icon-mobile-user {
        &::before {
            background-size: 1.45rem;
            background-image: url('~/assets/images/icons/user-icon.svg');
        }
    }
}

.header-link-mobile-wrapper {
    &:nth-of-type(2) {
        @apply bg-transparent;

        .header-link-mobile {
            @apply text-black;
        }
    }
}

.is-search-field-open {
    background-image: url('~/assets/images/icons/close-icon-black.svg');
    background-size: 1.29rem;
}
</style>
