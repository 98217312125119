<template>
    <div ref="categoriesDropdown" class="relative">
        <button
            class="px-7 py-2 lg:py-2 border rounded-full font-Inter-Bold text-base categories-btn whitespace-nowrap transition-all bg-opacity-0 w-full lg:w-auto justify-center dropdown-btn min-h-[2.775rem] lg:min-h-[2.7rem] lg:h-auto"
            :class="{ 'border-white text-white': light, 'border-black': !light, active: isDropdownOpen }"
            type="button"
            @click="$device.isDesktop ? (isDropdownOpen = !isDropdownOpen) : (isDropdownOpen = !isDropdownOpen)"
        >
            Termék böngésző
        </button>
        <transition :name="$device.isDesktop ? 'fade-in-modal' : 'slide'" mode="in-out">
            <div
                v-if="isDropdownOpen"
                class="relative lg:absolute left-0 lg:-left-28 lg:top-16 lg:rounded-md bg-blue-100 z-60 dropdown w-[calc(100%+2.5rem)] lg:min-h-[20rem] transition-all -ml-5 lg:ml-0 lg:w-auto transition-all mt-4 lg:mt-0"
                @mouseleave="closeDropdown"
            >
                <div class="flex min-h-[24rem] lg:min-h-[20rem] relative category-wrapper">
                    <div class="flex flex-col w-full lg:w-auto">
                        <div class="w-full pt-7 pb-10 px-4 lg:px-8">
                            <div
                                class="flex flex-col gap-y-4 relative items-center min-w-[17rem] w-full lg:w-auto mb-6"
                            >
                                <div
                                    class="w-full flex items-center justify-between pb-3 mb-1 border-b border-white border-opacity-20 cursor-pointer"
                                    @click="closeDropdown"
                                >
                                    <nuxt-link
                                        :to="localePath('pictures')"
                                        class="font-Inter-Bold text-lg text-white w-full category-link flex justify-between cursor-pointer"
                                        >Reprint kategóriák</nuxt-link
                                    >
                                </div>
                                <div
                                    v-for="category in categories"
                                    :key="`product-categry-${category.translations[0].id}`"
                                    class="w-full block"
                                >
                                    <div @click="closeDropdown" @mouseover="selectCategory(category.id)">
                                        <nuxt-link
                                            class="text-sm text-white font-Inter-Regular category-link lg:whitespace-nowrap hover:underline"
                                            :to="
                                                localePath({
                                                    name: 'products-category',
                                                    params: { category: category.translations[0].slug },
                                                })
                                            "
                                        >
                                            {{ category.translations[0].name }}
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-y-3 lg:gap-y-4 relative items-center w-full lg:w-auto mb-6">
                                <div
                                    class="w-full flex items-center justify-between pb-3 mb-1 border-b border-white border-opacity-20 cursor-pointer"
                                    @click="closeDropdown"
                                >
                                    <div class="w-full" @click="closeDropdown">
                                        <nuxt-link
                                            :to="localePath('editors')"
                                            class="font-Inter-Bold text-[1.1rem] text-white w-full category-link flex justify-between cursor-pointer"
                                            @mouseenter="selectEditor()"
                                            >Szerkesztők</nuxt-link
                                        >
                                    </div>
                                </div>
                                <div class="w-full block">
                                    <div @click="closeDropdown">
                                        <nuxt-link
                                            class="text-base text-white font-Inter-Regular category-link lg:whitespace-nowrap hover:underline"
                                            :to="localePath('editors-star-map')"
                                        >
                                            Csillagtérkép szerkesztő
                                        </nuxt-link>
                                    </div>
                                </div>
                                <div class="w-full block">
                                    <div @click="closeDropdown">
                                        <nuxt-link
                                            class="text-base text-white font-Inter-Regular category-link lg:whitespace-nowrap hover:underline"
                                            :to="localePath('editors-map')"
                                        >
                                            Térkép szerkesztő
                                        </nuxt-link>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full flex items-center justify-between cursor-pointer" @click="closeDropdown">
                                <div class="w-full" @click="closeDropdown">
                                    <nuxt-link
                                        :to="'/termekek/whispers-of-wood'"
                                        class="font-Inter-Bold text-[1.1rem] text-white w-full category-link flex justify-between cursor-pointer"
                                        @mouseenter="selectEditor()"
                                        >Gravírozott termékek</nuxt-link
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade-in-right">
                        <div
                            v-if="selectedCategory && $device.isDesktop"
                            class="py-7 px-4 lg:px-8 rounded-r-md w-full lg:w-[32rem] absolute left-0 top-0 lg:relative h-full lg:h-auto overflow-y-scroll lg:overflow-visible secondary-bg hidden lg:block"
                        >
                            <div v-if="selectedCategory" class="flex flex-col">
                                <div
                                    class="lg:aspect-w-14 lg:aspect-h-8 lg:min-w-[calc(100%+4rem)] lg:-ml-8 lg:min-h-[calc(100%+ 2rem)] mt-4 lg:-mt-7 rounded-md lg:rounded-none lg:rounded-tr-md overflow-hidden"
                                >
                                    <img
                                        class="w-full h-full object-cover"
                                        :src="selectedCategory.image"
                                        :alt="selectedCategory.translations[0].name"
                                    />
                                </div>
                                <div class="flex flex-col mt-7 lg:mb-3 text-white">
                                    <h3 class="font-Inter-Bold text-xl mb-4 lg:mb-4">
                                        <transition name="fade-in-value" mode="out-in">
                                            <span :key="selectedCategory.translations[0].name">
                                                {{ selectedCategory.translations[0].name }}
                                            </span>
                                        </transition>
                                    </h3>
                                    <h4
                                        v-if="selectedCategory?.translations[0].description"
                                        class="font-Inter-Light text-sm lg:text-base"
                                    >
                                        <transition name="fade-in-value" mode="out-in">
                                            <span :key="selectedCategory?.translations[0].description">
                                                {{ selectedCategory?.translations[0].description }}
                                            </span>
                                        </transition>
                                    </h4>
                                </div>
                            </div>
                            <div v-else-if="isCategoriesVisible" class="flex flex-wrap gap-y-4">
                                <nuxt-link
                                    v-for="category in categories"
                                    :key="`product-categry-${category.id}`"
                                    class="text-base text-white font-Inter-Semibold w-full lg:w-1/2 block category-link hover:underline pr-6"
                                    :to="category.slug"
                                >
                                    {{ category.name }}
                                </nuxt-link>
                            </div>
                            <button
                                class="absolute right-4 top-3 w-6 h-6 close-icon bg-center bg-no-repeat bg-contain transition-all hover:scale-105"
                                @click="$device.isDesktop ? toggleDropdown() : closeDropdown()"
                            ></button>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'vue-debounce';
export default {
    name: 'ProductCategoriesDropdown',
    props: {
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            categories: [],
            editors: [],
            selectedSubcategories: [],
            selectedCategory: null,
            selectedEditor: null,
            isSubcategoriesVisible: false,
            isCategoriesVisible: false,
            results: {
                products: [],
                categories: [],
            },
            isDropdownOpen: false,
        };
    },
    async fetch() {
        await this.$axios
            .$get('/categories')
            .then((res) => {
                if (res.result !== 'success') return;
                this.categories = res.data.filter((category) => category.translations[0].name !== 'Whispers of Wood');
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
    created() {
        /* await this.$axios
            .get('/api/product-categories')
            .then((response) => {
                this.categories = response.data;
            })
            .catch((err) => {
                console.log(err);
            });
        await this.$axios
            .get('/api/editors')
            .then((response) => {
                this.editors = response.data;
            })
            .catch((err) => {
                console.log(err);
            }); */
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        debounce,
        selectCategories() {
            this.selectedEditor = null;
            this.isCategoriesVisible = true;
        },
        selectEditor(editor) {
            this.selectedEditor = editor;
        },
        selectCategory(id) {
            this.selectedCategory = this.categories.find((category) => category.id === id);
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            this.selectedEditor = null;
            this.isCategoriesVisible = false;
            this.isDropdownOpen = false;
            if (this.$device.isMobile) {
                this.$store.commit('globals/toggleMobileMenu', false);
            }
        },
        handleClickOutside(event) {
            if (this.isDropdownOpen && !this.$refs.categoriesDropdown.contains(event.target)) {
                this.closeDropdown();
            }
        },
    },
};
</script>

<style scoped>
.categories-btn {
    @apply flex items-center transition relative;

    &::before {
        content: '\ea4e';
        font-family: 'remixicon', sans-serif;
        @apply h-5 w-5 flex items-center justify-center mr-2 text-2xl transition-transform;
    }

    &::after {
        content: '';
        @apply absolute block w-[calc(100%+4px)] h-[calc(100%+4px)] rounded-full border-4  bg-transparent opacity-0 transition;
        z-index: -1;
        border-color: rgba(34, 122, 255, 0.2);
    }

    &--light {
        &::before {
            background-image: url('~/assets/images/icons/arrow-down-stroke-icon-white.svg');
        }
    }

    &:hover,
    &.active {
        @media (min-width: 1200px) {
            @apply text-blue-100 border-blue-200;
            &::after {
                @apply opacity-100;
            }

            &::before {
                @apply text-blue-100;
            }
        }
    }

    &.active {
        &::before {
            @apply origin-center rotate-180;
        }
    }
}

.category-link {
    @apply flex items-center justify-between;

    &::after {
        content: '';
        @apply w-[6px] h-[14px] block bg-center bg-contain bg-no-repeat ml-16 transition;
        background-image: url('~/assets/images/icons/chevron-right-white.svg');
    }

    &:hover {
        &::after {
            @apply translate-x-1;
        }
    }
}

.close-icon {
    background-image: url('~/assets/images/icons/close-icon-fill.svg');
}

.category-wrapper {
    &::before {
        content: '';
        @apply absolute -top-4 ml-[24%] lg:ml-[8.5rem] h-0 w-0;
        border-style: solid;
        border-width: 0 16px 18px 16px;
        border-color: transparent transparent #227aff transparent;
        transform: rotate(0deg);
        @media (max-width: 992px) {
            border-width: 0 16px 12px 16px;
            top: -0.7rem;
        }
    }
}

.secondary-bg {
    background: linear-gradient(146deg, #227aff 0.95%, #1469e7 41.19%, rgba(20, 105, 231, 0) 100%);
    @media (min-width: 992px) {
        background: linear-gradient(146deg, #0c56c2 0.95%, #1469e7 41.19%, rgba(20, 105, 231, 0) 100%);
    }
}

.dropdown-btn {
    &:active {
        @apply bg-blue-100 bg-opacity-5;
    }
}

.dropdown {
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.15));
}
</style>
